.block {
    padding: 70px 0;
    border-bottom: 1px solid #7f7f7f;
  }

  .warna1{
    color:'#ff8000';
  }
  
  .warna2{
    color:'#0080ff';
  }


  .titleClients{
    transform: translateY(-8px);
  }
    

  @media screen
  and (max-device-width: 640px){
    html{
      .hero-block {
        margin: 7rem 0 0;
        .carousel-caption {
          display: none;
        }
     }
    }
}

  .hero-block {
    margin:0;
    .carousel-item:before {
        content: '';
        background: rgba(0, 0, 0, 0.7);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    .carousel-item{
        img {
          width: 100%;
        }
      }
      .carousel-caption {
        left: 25%;
        right: 25%;
        transform: translateY(50%);
        -webkit-transform: translateY(50%);
        bottom: 50%;
      }

      p {
        margin: 0 0 30px;
        color: #fff;
      }
      h3{
        color: #fff;
      }
  }

  .page-header{
    padding: 2rem 0;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(180,117,23,1) 0%, rgba(0,212,255,1) 100%);
    .pageheader-content{
      h2{
        color: #fff;
      }
    }
  }

  

  @media only screen and (max-width: 600px) {

    .page-header {
      margin-top: 6.25rem;
      padding: 1rem 0;
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(180, 117, 23, 1) 0%,
        rgba(0, 212, 255, 1) 100%
      );
  
      .pageheader-content {
        h2 {
          color: #fff;
        }
      }

      .card-content{
        margin-bottom: 50px;
      }
    }
  
  }

  .card-content{
    padding: 5px;
    width: 80%;
    align-items: center;
    justify-content: center;
    button{
      background:linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(180, 117, 23, 1) 0%,
        rgba(0, 212, 255, 1) 100%
      );
      font-family: 'Courier New', Courier, monospace;
      font-size: 20px;
    }
  }
  .card-content:hover {
    transition: transform 0.2s ease-in-out;
    transform: scale(1.2);
  }

  .card-inhouse{
    padding: 5px;
    width: 60%;
    align-items: center;
    justify-content: center;
    button{
      background:#0080ff;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 20px;
      width: 100%;
    }
  }
  .card-inhouse:hover {
    transition: transform 0.2s ease-in-out;
    transform: scale(1.2);
  }


  button{
    background:#0080ff;
    color: #fff;
    display: block;
    width: 100%;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .loading-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:1rem;
    //height:100vh;
  }
  
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 7px solid #f3f3f3;
    border-top: 7px solid #337ab7;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .loading-button {
    width: 50px;
    height: 50px;
    border: 7px solid #f3f3f3;
    border-top: 7px solid #337ab7;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  .form-group {
    position: relative;
  }
  
  .input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
  
  .input-icon i {
    display: block;
    font-size: 1.5em; 
  }

  .display-klien {
    position: relative;
    background-image: url('assets/atlas.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100vw 100%;
  }
  
  .display-klien::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
  }

  .stu-feed-inner{
    max-width: 30vw;
  }

  @media screen and (max-width: 767px) {
    .stu-feed-inner{
      max-width: 100vw;
    }  
  }

  .stu-feed-bottom p {
    word-wrap: break-word;
  }
  


.card{
    box-shadow: 0 0 30px rgba(0,0,0,0.5);
    overflow: hidden;
    border-radius: 20px;
}

.img1 img{
    height:150px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    width:100%;
}

.img2 img{
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  width:200px;
  height: 200px;
  border-radius: 50%;
  border: 7px solid #fff;
  margin-top:-80px;
}

.card:hover .img2 img{
  border-color: rgb(33, 43, 226);
  transition: 0.7s;
}

.main-text{
  padding: 30px 0;
  text-align: center;
}

.main-text h2{
    text-transform: uppercase;
    font-weight:900;
    font-size:20px;
    margin:0 0 20px;
}

.main-text p{
  font-size:20px;
  padding:0 35px;
}

.socials{
  text-align: center;
  padding-bottom:20px;
}
  
.socials i{
  font-size:20px;
  color: rgb(0, 16, 139);
  padding: 0 10px;
}

.animation {
  animation: moveUpDown 2s infinite;
}

@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
}

.feature-icon {
  border: 1px solid #f16126;
  i {
    justify-content: center;
    align-items: center;
    color: #f16126;
  }
}

.feature-icon :hover {
  color: #f16126;
  i {
    justify-content: center;
    align-items: center;
    color: #000000;
  }
}



//====================what whe do===================
.feature-inner-wwd {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 350px;
  border: 5px solid #ff8000;
  padding: 20px;
  transition: all 0.3s ease;
  border-radius: 1.25rem;
}

.feature-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.feature-content {
  text-align: center;
}

.feature-content h4 {
  margin-top: 10px;
}

.feature-content p {
  margin-top: 5px;
}


@media (max-width: 767px) {
  .swiper-mobile-hidden {
    display: none;
  }
  .display-klien{
    display: none;
  }
  .collaborate{
    display: none;
  }
}

.instructor-thumb img {
  width: 200px; /* Ubah sesuai dengan lebar yang diinginkan */
  height: 200px; /* Ubah sesuai dengan tinggi yang diinginkan */
  object-fit: cover;
}
